<template>
  <add-device></add-device>
</template>

<script>
import AddDevice from './AddDevice.vue';

export default {
  components: {
    AddDevice,
  },
};
</script>
